import * as React from 'react';
import { store } from '../src/store/store';
import withUser from './utils/withUser';
const DashboardDefault = React.lazy(() => import('./Demo/Dashboard/Default'));
const DashboardSales = React.lazy(() => import('./Demo/Dashboard/Sales'));
const DashboardCRM = React.lazy(() => import('./Demo/Dashboard/Crm'));
const DashboardAnalytics = React.lazy(() => import('./Demo/Dashboard/Analytics'));
const DashboardProject = React.lazy(() => import('./Demo/Dashboard/Project'));
const Users = React.lazy(() => import('./Demo/Users/UserList'));
const Profile = React.lazy(() => import('./Demo/Users/UserProfileNew'));
const Settings = React.lazy(() => import('./Views/Settings'));
const SupportForm = React.lazy(() => import('./Views/SupportForm'));
const DeviceManagement = React.lazy(() => import('./Views/DeviceManagement'));
const AddEditDevice = React.lazy(() => import('./Views/AddEditDevice'));
const Subscription = React.lazy(() => import('./Views/Subscription'));
const PdoSignupCommission = React.lazy(() => import('./Demo/Accounting/PdoSignupCommission'));
const SubscriptionCommission = React.lazy(() => import('./Demo/Accounting/SubscriptionCommission'));
// const EvoucherPurchase = React.lazy(() => import('./Demo/Accounting/EvoucherPurchase'));
// const EvoucherSales = React.lazy(() => import('./Demo/Accounting/EvoucherSales'));
// const TotalRevenue = React.lazy(() => import('./Demo/Accounting/TotalRevenue'));
const BuyEvouchers = React.lazy(() => import('./Demo/Evouchers/buyEvouchers'));
const SellEvouchers = React.lazy(() => import('./Demo/Evouchers/sellEvoucher'));
const MyEvoucher = React.lazy(() => import('./Demo/Evouchers/myEvoucher'));
const MyPDO = React.lazy(() => import('./Demo/MyPdo/mypdo'));
const Marketing = React.lazy(() => import('./Demo/Marketing/marketing'));

const Paymentstatus = store.getState().user?.distributorPayment;

const routes = [
	{ path: '/users', exact: true, name: 'Users', component: Users },
	{ path: '/profile', exact: true, name: 'Profile', component: withUser(Profile) },
	{ path: '/settings', exact: true, name: 'Settings', component: withUser(Settings) },

	{ path: '/subscription', exact: true, name: 'Subscription', component: withUser(Subscription) },

	{ path: '/support', exact: true, name: 'SupportForm', component: withUser(SupportForm) },

	{ path: '/accounting/commission', exact: true, name: 'Accounting', component: withUser(PdoSignupCommission) },
	{
		path: '/accounting/renewal',
		exact: true,
		name: 'SubscriptionCommission',
		component: withUser(SubscriptionCommission)
	},
	{ path: '/mypdo', exact: true, name: 'MyPDO', component: withUser(MyPDO) },

	{ path: '/marketing', exact: true, name: 'Marketing', component: withUser(Marketing) }
	// { path: '/accounting/evoucherPurchase', exact: true, name: 'EvoucherPurchase', component: EvoucherPurchase },
	// { path: '/accounting/evouchersales', exact: true, name: 'EvoucherSales', component: EvoucherSales },
	// { path: '/accounting/totalRevenue', exact: true, name: 'TotalRevenue', component: TotalRevenue },

	// { path: '/evouchers/buyevoucher', exact: true, name: 'BuyEvouchers', component: BuyEvouchers },
	// { path: '/evouchers/myevoucher', exact: true, name: 'MyEvouchers', component: MyEvoucher },
	// { path: '/evouchers/sellevoucher', exact: true, name: 'SellEvouchers', component: SellEvouchers },

	// { path: '/dashboard/default', exact: true, name: 'Analytics', component: DashboardDefault },
	// { path: '/dashboard', exact: true, name: 'Crypto', component: DashboardSales },
	// { path: '/dashboard/crm', exact: true, name: 'Crypto', component: DashboardCRM },
	// { path: '/dashboard/analytics', exact: true, name: 'Analytics', component: DashboardAnalytics },
	// { path: '/dashboard/project', exact: true, name: 'Crypto', component: DashboardProject },
	// { path: '/device-management', exact: true, name: 'Device Management', component: DeviceManagement },

	// { path: '/device-management/add', exact: true, name: 'Add Device', component: AddEditDevice },
	// { path: '/device-management/edit/:device_id', exact: true, name: 'Edit Device', component: AddEditDevice },
];

export default routes;
