import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const isDistributorPayment = () => {
	const ispayment = localStorage.getItem('payment');

	if (typeof window !== 'undefined') {
		return ispayment == 'true' ? true : false;
	}
	// return false;
};

function withUser(Child) {
	return (props) => {
		const histrory = useHistory();

		useEffect(() => {
			if (isDistributorPayment()) {
				// console.log(18, isDistributorPayment());
			} else {
				histrory.push('/subscription');
			}
		}, []);

		return <Child {...props}></Child>;
	};
}

export default withUser;
